import { render, staticRenderFns } from "./ft-frame.vue?vue&type=template&id=16c106c0&scoped=true"
import script from "./ft-frame.vue?vue&type=script&lang=js"
export * from "./ft-frame.vue?vue&type=script&lang=js"
import style0 from "./ft-frame.vue?vue&type=style&index=0&id=16c106c0&prod&lang=less&scoped=true"
import style1 from "./ft-frame.vue?vue&type=style&index=1&id=16c106c0&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c106c0",
  null
  
)

export default component.exports