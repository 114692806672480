import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/assets/css/theme/index.css'
import '@/assets/css/common.less'
import App from './App.vue'
import router from './router'
import filters from '@/utils/filter'
import ftComponent from '@/components/index.js'
import currentUser from '@/utils/current_user'
import { createInstance } from '@/utils/axios'

Vue.prototype.$axios = createInstance('/mediation/api/v1')
Vue.prototype.$axiosMediator = createInstance('/mediator/api/v1')
Vue.prototype.$currentUser = currentUser
Vue.config.productionTip = false
Vue.use(filters)
Vue.use(ElementUI, { size: 'small' })
Vue.use(ftComponent)
Vue.mixin({
  methods: {
    getPermissionList () {
      return this.$currentUser.permission
    },
    hasPermission (code) {
      const permission = this.getPermissionList()
      return permission.some(row => {
        return row.startsWith(code)
      })
    }
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
