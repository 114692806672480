import GreyList from './gray-list'
import WhiteList from './white-list'
import homeRoute from './home'
import systemRoute from './system'
import orderRoute from './order'

const routerList = [
  ...homeRoute,
  ...orderRoute,
  ...systemRoute
]

export default { GreyList, WhiteList, routerList }
