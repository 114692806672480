export default [
  {
    path: '/order',
    meta: {
      icon: '',
      name: 'order',
      title: '订单中心',
      code: 'order'
    },
    children: [
      {
        path: '/service-open',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'service-open',
          title: '服务开通管理',
          code: 'order:service-open'
        },
        component: () => import('@/webapp/order/service-open.vue')
      },
      {
        path: '/service-consume',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'service-consume',
          title: '服务消耗统计',
          code: 'order:service-consume'
        },
        component: () => import('@/webapp/order/service-consume.vue')
      },
      {
        path: '/service-manage',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'service-manage',
          title: '服务管理',
          code: 'order:service-manage'
        },
        component: () => import('@/webapp/order/service-manage.vue')
      }

    ]

  }
]
