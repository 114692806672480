<template>
    <div id="textAreaTemplate">
      <div style="text-align: right;">
        <el-upload
          class="uploadBtn"
          style="display:none"
          ref="fileRefs"
          action=""
          :before-upload="beforeUpload"
          :show-file-list="false"
          :disabled="disabled"
        >
          <el-button :disabled="disabled">导入</el-button>
        </el-upload>
      </div>
      <div class="tinymcebox">
        <editor ref="editor" v-model="content" :init="editorOptions" :disabled="disabled"></editor>
      </div>
    </div>
  </template>
<script>
import 'mammoth/mammoth.browser.js'
import Mammoth from 'mammoth'
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/code'
import 'tinymce/plugins/link'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/template'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/emoticons'
import { uploadFile } from '@/utils/cos'
export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    clearStyle: {
      type: Boolean,
      default: false
    },
    tools: {
      type: String,
      default () {
        return ''
      }
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.content = newValue
      },
      immediate: true
    },
    content (newValue) {
      this.$emit('input', newValue)
    }
  },
  data () {
    return {
      disabled: false,
      content: '',
      // 初始化配置
      init: {
        _target: () => this,
        menubar: false, // 菜单栏显隐
        language_url: '/js/tinymce/langs/zh-Hans.js',
        language: 'zh-Hans',
        skin_url: '/js/tinymce/skins/ui/oxide',
        height: 400,
        min_height: 400,
        max_height: 400,
        toolbar_mode: 'wrap',
        font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px',
        // content_style: '.mce-content-body {font-size:14px;font-family:Arial,sans-serif;}',
        plugins:
            'preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap  nonbreaking insertdatetime advlist lists wordcount autosave autoresize emoticons',
        toolbar:
           // eslint-disable-next-line no-multi-str
           'undo redo paste | blocks fontfamily fontsize forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent  \
           | bullist numlist |  blockquote subscript superscript removeformat | table image charmap fullscreen preview ',

        content_css: '/js/tinymce-content.css',
        branding: false,
        paste_auto_cleanup_on_paste: this.clearStyle,
        paste_remove_styles: this.clearStyle,
        paste_remove_styles_if_webkit: this.clearStyle,
        paste_strip_class_attributes: false,
        formats: {
          removeformat: [ // 清除所选内容格式
            { selector: 'b,strong,em,i,font,u,strike,div,a,br,code,pre', remove: 'all', split: true, expand: false, block_expand: true, deep: true }
          ]
        },
        init_instance_callback: function (editor) {
          editor.getContainer().querySelector('button.tox-statusbar__wordcount').click()
        },
        setup: (editor) => {
          const _this = this
          editor.ui.registry.addButton('importbtn', {
            text: '导入word',
            // icon: 'iconfont icon-daoruicon_lan',
            onAction: function () {
              _this.$refs.fileRefs.$refs['upload-inner'].handleClick()
            }
          })
        },
        paste_data_images: this.clearStyle,
        // 图片上传转码
        images_upload_handler: (blobInfo, progress) =>
          new Promise((resolve, reject) => {
            uploadFile(blobInfo.blob()).then((data) => {
              resolve(data)
            })

            // resolve(
            //   'data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64()
            // )
          })
      }
    }
  },
  computed: {
    editorOptions () {
      const init = this.init
      if (this.tools) {
        init.toolbar = this.tools
      }
      if (this.clearStyle) {
        init.paste_auto_cleanup_on_paste = true
        init.paste_remove_styles = true
        init.paste_remove_styles_if_webkit = true
        init.paste_remove_styles_if_webkit = true
      }
      return init
    }
  },
  mounted () {
  },
  methods: {
    beforeUpload (file, fileList) {
      const self = this
      const reader = new FileReader()
      reader.onloadend = function (event) {
        const arrayBuffer = reader.result
        // 将word 转换成html
        const loading = self.$loading({
          lock: true,
          text: '文件解析中....',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.3)'
        })
        Mammoth.convertToHtml({ arrayBuffer: arrayBuffer }).then(function (
          resultObject
        ) {
          setTimeout(() => {
            const content = tinymce.activeEditor.getContent() + resultObject.value
            tinymce.activeEditor.setContent(content)
            loading.close()
          }, 100)
        })
      }
      reader.readAsArrayBuffer(file)
      return false
    },
    getWordCount () {
      const wordcount = this.$refs.editor.editor.plugins.wordcount
      // return wordcount.body.getWordCount()
      return wordcount.body.getCharacterCount()
    }
  }
}
</script>
  <style lang="less" scoped>
  #textAreaTemplate {
    .uploadBtn {
      display: inline-block;
      margin: 0 10px;
    }
    .tabTiyle {
      border-left: 8px solid rgba(36, 145, 255, 1);
      padding-left: 15px;
      font-size: 21px;
      text-align: left;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  </style>
  <style>
  .dialog {
    text-align: left;
  }
  </style>
