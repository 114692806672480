import { city, province, area } from './area'
export const AREA_TREE = province.map(row => {
  let v = false
  let cityList = city.filter(r => r.p === row.p)
  if (cityList.length === 0) {
    v = true
    cityList = area.filter(rr => rr.p === row.p)
  }
  return {
    label: row.n,
    value: row.c,
    children: cityList.map(r => {
      return {
        label: r.n,
        value: r.c,
        children: v
          ? null
          : area.filter(rr => rr.p === r.p && rr.t === r.t).map(rr => {
            return {
              label: rr.n,
              value: r.c
            }
          })
      }
    })
  }
})

export const ID_TYPE = [null, '居民身份证']

export const ENTRUST_TYPE = [null, '个人', '企业']

export const DISPUTE_TYPE = [null, '合同纠纷', '债务纠纷', '保险纠纷', '公司纠纷', '知识产权纠纷']

export const APPLY_STATUS = ['待受理', '受理中', '申请通过', '申请驳回']

export const EDUCATION_TYPE = ['高中及以下', '大专', '本科', '研究生及以上']

// export const SERVICE_ITEM = [{ value: 1, label: '文书代写' }, { value: 2, label: '文书审核' }, { value: 5, label: '标准合同数' }, { value: 6, label: '企业成员数' }, { value: 4, label: '合同智能审核' }, { value: 8, label: '合同对比' }, { value: 9, label: '企业风险体检' }, { value: 12, label: '合同履约' }, { value: 13, label: '法律咨询' }, { value: 14, label: '电子签署' }, { value: 15, label: '数据保全' }, { value: 16, label: '人工咨询' }, { value: 17, label: 'AI问答' }, { value: 18, label: '文档分析' }, { value: 19, label: '文档咨询' }, { value: 20, label: '存储容量' }, { value: 21, label: '语音识别' }, { value: 22, label: '上门服务' }, { value: 23, label: '法律文书' }, { value: 24, label: '定制法律培训' }, { value: 25, label: '律师服务' }]

export const SERVICE_ITEM = [{ value: 14, label: '电子签署' }, { value: 15, label: '数据保全' }]

export const UNIT = ['次', '份', '个', 'G', '分钟', '页', '人', '篇']
