import dayjs from 'dayjs'
import { ID_TYPE, ENTRUST_TYPE, DISPUTE_TYPE, APPLY_STATUS, EDUCATION_TYPE } from './constant'

export function dateFormat (date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) return '-'
  return dayjs(date).format(format)
}

export function hidePhone (phone) {
  if (!phone) {
    return ''
  } else {
    return phone.substr(0, 3) + '****' + phone.substr(7)
  }
}

export function mediationStatus (status) {
  return ['已停用', '已启用'][status] || '-'
}

export function userStatus (status) {
  return ['已停用', '已启用'][status] || '-'
}

export function authStatus (status) {
  return ['未认证', '已认证'][status] || '-'
}

export function education (value) {
  return ['高中及以下', '大专', '本科', '研究生及以上'][value] || '-'
}

export function orderServiceStatus (status) {
  return ['', '已生效', '已用完', '已过期'][status] || '-'
}

export function entrustType (value) {
  return ENTRUST_TYPE[value] || '-'
}

export function idType (value) {
  return ID_TYPE[value] || '-'
}

export function disputeType (value) {
  return DISPUTE_TYPE[value] || '-'
}

export function applyStatus (value) {
  return APPLY_STATUS[value] || '-'
}

export function educationType (value) {
  return EDUCATION_TYPE[value] || '-'
}

export default {
  install (Vue) {
    Vue.filter('dateFormat', dateFormat)
    Vue.filter('hidePhone', hidePhone)
    Vue.filter('mediationStatus', mediationStatus)
    Vue.filter('userStatus', userStatus)
    Vue.filter('authStatus', authStatus)
    Vue.filter('education', education)
    Vue.filter('idType', idType)
    Vue.filter('entrustType', entrustType)
    Vue.filter('disputeType', disputeType)
    Vue.filter('applyStatus', applyStatus)
    Vue.filter('educationType', educationType)
    Vue.filter('orderServiceStatus', orderServiceStatus)
  }
}
