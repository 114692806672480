// 首页
export default [
  {
    path: '/home',
    meta: {
      icon: '',
      name: 'home',
      title: '首页',
      code: 'home'
    },
    children: [
      {
        path: '/mediation-center',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediation-center',
          title: '调解中心管理',
          code: 'home:mediation'
        },
        children: [
          {
            path: '/list',
            meta: {
              name: 'list',
              title: '调解中心列表',
              code: 'home:mediation:list'
            },
            component: () => import('@/webapp/home/center.vue')
          },
          {
            path: '/register-audit',
            meta: {
              name: 'register-audit',
              title: '注册审核',
              code: 'home:mediation:register-audit',
              badge: 'registerAuditNum'
            },
            component: () => import('@/webapp/home/register-audit.vue')
          },
          {
            path: '/audit',
            meta: {
              name: 'audit',
              title: '待审核认证',
              code: 'home:mediation:audit',
              badge: 'authAuditNum'
            },
            component: () => import('@/webapp/home/audit.vue')
          },
          {
            path: '/audit-record',
            meta: {
              name: 'audit-record',
              title: '审核记录',
              code: 'home:mediation:audit-record'
            },
            component: () => import('@/webapp/home/audit-record.vue')
          },
          {
            path: '/public-validate-record',
            meta: {
              name: 'public-validate-record',
              title: '对公验证记录',
              code: 'home:mediation:public-validate-record'
            },
            component: () => import('@/webapp/home/public-validate-record.vue')
          }
        ]

      },
      {
        path: '/mediator-list',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediator-list',
          title: '调解员管理',
          code: 'home:mediator'
        },
        component: () => import('@/webapp/home/mediator-list.vue')
      },
      {
        path: '/user',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediation-center',
          title: '用户管理',
          code: 'home:user'
        },
        component: () => import('@/webapp/home/user.vue')
      },
      {
        path: '/apply',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'apply',
          title: '调解申请管理',
          code: 'home:apply'
        },
        children: [
          {
            path: '/list',
            meta: {
              name: 'list',
              title: '申请列表',
              code: 'home:apply:list'
            },
            component: () => import('@/webapp/home/apply/list.vue')
          },
          {
            path: '/all',
            meta: {
              name: 'list',
              title: '全部申请',
              code: 'home:apply:all'
            },
            component: () => import('@/webapp/home/apply/all.vue')
          }
        ]
      },
      {
        path: '/case',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'case',
          title: '调解案件管理',
          code: 'home:case'
        },
        component: () => import('@/webapp/home/case.vue')
      }

    ]

  }
]
