import { createInstance } from './axios'
const axios = createInstance('/mediation/api/v1')

class CurrentUser {
  info = null
  async loadData () {
    const res = await axios.get('/user/info')
    this.info = res.data.result
  }

  async updateData (data) {
    await axios.put('/user/info', data)
    await this.loadData()
  }

  get info () {
    return this.info
  }

  get token () {
    return localStorage.getItem('token')
  }

  set token (token) {
    localStorage.setItem('token', token)
  }

  get permission () {
    const josnStr = localStorage.getItem('permission') || '[]'
    return JSON.parse(josnStr)
  }

  set permission (permission) {
    localStorage.setItem('permission', JSON.stringify(permission || []))
  }

  clear () {
    localStorage.removeItem('token')
    localStorage.removeItem('permission')
  }
}

export default new CurrentUser()
