import COS from 'cos-js-sdk-v5'
import axios from 'axios'

export async function uploadFile (file, option) {
  const opt = Object.assign({
    onProgress: function (progressData) {
      console.log(progressData)
    }
  }, option)
  const res = await axios.get('/mediation/api/v1/file/cos')
  const data = res.data.result
  const bucket = data.bucket
  const region = data.region
  const uuid = data.uuid
  const cos = new COS({
    getAuthorization: async (options, callback) => {
      // eslint-disable-next-line node/no-callback-literal
      callback({
        TmpSecretId: data.tmpSecretId,
        TmpSecretKey: data.tmpSecretKey,
        XCosSecurityToken: data.sessionToken,
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000，建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        ExpiredTime: data.expiredTime // 时间戳，单位秒，如：1580000900
      })
    }
  })
  const arr = file.name.split('.')
  const uploadOption = {
    Bucket: bucket,
    Region: region,
    Key: uuid + '.' + arr[arr.length - 1],
    Body: file,
    onHashProgress: opt.onProgress || null,
    onProgress: opt.onProgress || null
  }
  // 分片上传文件
  const slice = new Promise((resolve, reject) => {
    cos.sliceUploadFile(uploadOption,
      (err, data) => {
        if (err) reject(err)
        else {
          resolve('https://' + data.Location)
        }
      }
    )
  })
  // 直接上传文件
  const direct = new Promise((resolve, reject) => {
    cos.uploadFile(uploadOption,
      (err, data) => {
        if (err) reject(err)
        else {
          resolve('https://' + data.Location)
        }
      }
    )
  })
  // 文件大于 5M 使用分片上传，否则直传
  if (file.size > (1024 * 1024 * 5)) {
    return await slice
  } else {
    return await direct
  }
}

export async function downloadFile (url, filename = null) {
  const res = await axios.get(url, { responseType: 'blob' })
  const miniType = res.headers['content-type']
  const blob = new Blob([res.data], { type: miniType })
  if (!filename) {
    return blob
  } else {
    const localUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = filename
    a.href = localUrl
    a.click()
    window.URL.revokeObjectURL(localUrl)
  }
}
